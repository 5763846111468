<template>

  <div id='conversation-badge' @click="openChat">
    <Icon :tooltip="opened ? 'Fechar suporte' : 'Abrir suporte'">
      <SupportIcon id="suport-icon" class="icon" />
    </Icon>
    <div v-if="unreadIndicator" class="unread-indicator">
      {{ unreadIndicator }}
    </div>
  </div>
</template>

<script>
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager';

export default {
  components: {
    Icon: () => import('../../components/General/Icon'),
    SupportIcon: () => import('@/assets/icons/support.svg')
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      user: getCurrentUser(),
      opened: false,
      unreadIndicator: 0,
    }
  },
  async mounted() {
    
    
  },
  methods: {
    openChat() {
      if (this.opened) {
        window.HubSpotConversations.widget.remove()
        this.opened = false
      } else {
        window.HubSpotConversations.widget.load({ widgetOpen: true })
        this.opened = true
      }
      this.unreadIndicator = 0
    },
    async onConversationsAPIReady() {
      window.hsConversationsSettings = {
        loadImmediately: false
      }
      try {
        const { data } = await this.api.getHubSpotToken(this.clinic.id)
        if (!data.token) return
        window.hsConversationsSettings = {
          identificationEmail: this.user.email,
          identificationToken: data.token
        }
        window.HubSpotConversations.on('unreadConversationCountChanged', payload => {
          this.unreadIndicator = payload.unreadCount
        })
        window.HubSpotConversations.on('widgetClosed', event => {
          window.HubSpotConversations.widget.remove()
          this.opened = false
        });
      } catch (error) {
        console.log('Err getHubSpotToken: ', error)
      }
    }
  },
  watch: {
    'window.HubSpotConversations': {
      async handler() {
        window.HubSpotConversations && window.HubSpotConversations.widget.remove()
        await this.onConversationsAPIReady()
      },
      immediate: true
    }
  }

}
</script>
<style lang="scss">
#conversation-badge {
  z-index: 10;
  position: relative;
  .icon {
    width: 24px;
    fill: rgb(127, 145, 210);
  }
  .title__xxl {
    color: var(--blue-500);
  }
}
</style>